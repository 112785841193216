<template>
  <div class="child-page-div">
    <div class="page-content-div">
      <table-box ref="tableBox" :tableTitle="'行业资讯评论管理'" :tableFieldNum="tableFieldNum" :action="action" :params="queryParams" :reloadData.sync="reloadData">
        <template #table-head>
          <th v-for="(item, index) in tableField" v-show="item.state" :key="index">{{ item.alias }}</th>
          <th v-if="isOperation" class="operation-th">操作</th>
        </template>
        <template #table-body="props">
          <tr>
            <template v-for="(item, index) in tableField">
              <td v-if="item.name === 'content'"><div class="content-div">{{props.item['content']}}</div></td>
              <td v-else-if="item.isHtml" :key="index" v-show="item.state" v-html="item.value(props.item, item.name)" />
              <td v-else :key="index" v-show="item.state">{{ item.value(props.item, item.name) }}</td>
            </template>
            <td v-if="isOperation">
              <div class="operation-btn-div">
                <span v-if="$validateAuthority(2400000,2401000,2401005) && !Object.prototype.hasOwnProperty.call(props.item, 'targetName')" class="cursor-el" @click="onReply(props.item)">回复</span>
                <span v-if="$validateAuthority(2400000,2401000,2401006)" class="cursor-el" @click="onDel(props.item)">删除</span>
              </div>
            </td>
          </tr>
        </template>
      </table-box>
    </div>
  </div>
</template>

<script>
import TableBox from "../../components/TableBox";
import { getNewsCommentItems, postNewsCommentReply, postNewsCommentDel } from "../../common/api";

export default {
  name: "NewsComments",
  components: { TableBox },
  data() {
    return {
      action: getNewsCommentItems,
      reloadData: false,
      searchType: "1",
      searchMsg: "",
      queryParams: {
        type: 0,
        msg: "",
        pageSize: 20
      },
      tableField: [
        { state: true, alias: "评论编号", name: "id", title: "id", isHtml: false, value: this.getObjValue },
        { state: true, alias: "评论人", name: "nickName", title: "nickName", isHtml: false, value: this.getObjValue },
        { state: true, alias: "被评论人", name: "targetName", title: "targetName", isHtml: false, value: this.getObjValue },
        { state: true, alias: "评论内容", name: "content", title: "content", isHtml: false, value: this.getObjValue },
        { state: true, alias: "评论时间", name: "createTime", title: "createTime", isHtml: false, value: this.getObjValue }
      ]
    };
  },
  computed: {
    isOperation() {
      return this.$hasAuthority(2400000,2401000,[2401005,2401006]);
    },
    tableFieldNum() {
      let num = this.isOperation ? 1 : 0;
      this.tableField.forEach(function(value) {
        if (value.state) {
          ++num;
        }
      });
      return num;
    }
  },
  created() {
    this.queryParams.id = this.$route.query.id;
  },
  methods: {
    getObjValue(item, field) {
      return item[field];
    },
    onReloadData() {
      this.reloadData = true;
    },
    onSearch() {
      this.queryParams.type = this.searchType;
      this.queryParams.msg = this.searchMsg.trim();
    },
    onReply(item) {
      let _this = this;
      this.$layer.prompt(
        {
          formType: 3,
          maxlength: 200,
          area: ["500px", "300px"],
          title: "回复评论"
        },
        function(value, index){
          if(typeof(value) !== "string"){
            _this.$layer.msg("请输入回复内容");
            return;
          }
          value = value.trim();
          if(value.length < 1){
            _this.$layer.msg("请输入回复内容");
            return;
          }
          if(value.length > 200){
            _this.$layer.msg("请回复内容不能超过200个字符");
            return;
          }

          postNewsCommentReply({
            id: _this.queryParams.id,
            targetId: item.userId,
            content: value
          })
            .then(() => {
              _this.onReloadData();
              _this.$layer.msg("操作成功");
              _this.$layer.close(index);
            })
            .catch(error => {
              _this.$layer.close(index);
              if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
                _this.$layer.msg(error.data.msg);
              } else {
                _this.$layer.msg("操作失败");
              }
            });
        }
      );
    },
    onDel(item) {
      this.$layer.confirm(`是否删除评论【${item.content}】？`, layerid => {
        postNewsCommentDel({ id: item.id })
          .then(() => {
            this.$layer.msg("操作成功");
            this.onReloadData();
            this.$layer.close(layerid);
          })
          .catch(error => {
            this.$layer.close(layerid);
            if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
              this.$layer.msg(error.data.msg);
            } else {
              this.$layer.msg("操作失败");
            }
          });
      });
    }
  }
}
</script>

<style lang="less" scoped>
.child-page-div {
  width: 100%;
  .page-content-div {
    border-radius: 5px;
    background-color: #ffffff;
    padding: 30px 20px 10px 20px;
    .search-div {
      height: 100%;
      display: flex;
      select {
        width: 110px;
        height: 100%;
        border: 1px solid #dcdcdc;
      }
      .search-input {
        width: 140px;
        height: 33px;
        border: 1px solid #dcdcdc;
        padding: 0 15px;
        margin-left: 5px;
      }
      .search-btn {
        width: 80px;
        height: 100%;
        color: #f4f6f5;
        border: none;
        background: #4676c8;
      }
    }
    .operation-th {
      width: 80px;
    }
    .table-sub-head-th {
      .div-btn {
        float: left;
        width: 88px;
        height: 23px;
        border: 1px solid #e5e5e5;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          margin-right: 10px;
        }
      }
      .append-btn {
        margin-left: 15px;
        margin-right: 10px;
      }
    }
    .content-div {
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .operation-btn-div {
      display: flex;
      justify-content: space-around;
    }
  }
}
</style>